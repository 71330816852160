export default {
    states: [
        {
          key: "ci",
          text: "Côte d'Ivoire",
        },
        {
          key: "africa",
          text: "Afrique",
        },
        {
          key: "world",
          text: "Monde entier",
        },
    ],
}