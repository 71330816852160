<template>
  <div>
    <!-- Table -->
    <b-table ref="refInvoiceListTable" :items="data" responsive :fields="tabledata.columns" primary-key="id"
      :current-page="tabledata.currentPage" :per-page="tabledata.perPage" :filter="tabledata.filter" show-empty
      empty-text="Auncune donnée disponible" class="position-relative" :busy.sync="tabledata.isBusy">
      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner small type="grow" />
          <strong> Veuillez patienter...</strong>
        </div>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon :id="`post-row-${data.item.slug}-preview-icon`" icon="EyeIcon" size="16" class="mr-1"
            @click.stop="showDetails(data.item)" />

          <feather-icon v-if="canEdit" :id="`post-row-${data.item.slug}-edit-icon`" icon="EditIcon" size="16" class="mr-1"
            @click.stop="editDetails(data.item)" />

          <feather-icon v-if="canRemove" :id="`post-row-${data.item.slug}-trash-icon`" icon="TrashIcon" size="16" class=""
            @click.stop="deletePrompt(data.item.slug)" />
        </div>
      </template>
    </b-table>
    <!-- Table end -->

    <div class="mx-2 mb-2">
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <span class="text-nowrap ">
            Affichage 1 à
          </span>
          <b-form-select v-model="tabledata.perPage" :options="tabledata.pageOptions" class="mx-1 per-page-selector" />
          <span class="text-nowrap"> de {{ data.length }} éléments </span>
        </div>
        <div>
          <b-pagination :value="1" v-model="tabledata.currentPage" :total-rows="tabledata.totalRows"
            :per-page="tabledata.perPage" first-number last-number prev-class="prev-item" next-class="next-item"
            class="mt-1 mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormInput,
  BPagination,
  BFormSelect,
  BTable,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    tabledata: {
      type: Object,
      default: () => { },
    },
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    canRemove: {
      type: Boolean,
      default: () => true,
    },
    deletePrompt: Function,
    edit: Function,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormInput,
    BPagination,
    BFormSelect,
    BTable,
    BSpinner,
  },
  methods: {
    showDetails(item) {
      this.$emit("show-details", item);
    },
    editDetails(item) {
      this.$emit("edit-details", item);
    },
  },
  setup() {
    const postStatusVariantAndIcon = requestStat => {
      if (requestStat === 'pending') return { variant: 'warning', icon: 'PieChartIcon', text: 'En attente' }
      if (requestStat === 'active') return { variant: 'success', icon: 'CheckCircleIcon', text: 'Active' }
      if (requestStat === 'archived') return { variant: 'danger', icon: 'XIcon', text: 'Désactivé' }
      if (requestStat === 'ended') return { variant: 'info', icon: 'CheckCircleIcon', text: 'Terminé' }
      return { variant: 'primary', icon: 'XIcon' }
    }

    return {
      postStatusVariantAndIcon,
    }
  },
}
</script>
