<template>
  <div>
    <div></div>
    <b-form @submit.prevent="save">
      <!-- CATEGORY -->
      <b-form-group label="* Catégorie" label-for="category">
        <v-select
          id="category"
          v-model="formdata.fields.category"
          label="title"
          value="slug"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          placeholder="Sélectionner une categorie"
          :options="buildDataCategory"
        />
      </b-form-group>
      <!-- TITLE -->
      <b-form-group label="* Titre" label-for="title">
        <b-form-input
          id="title"
          v-model="formdata.fields.title"
          placeholder="Titre"
          required
        />
      </b-form-group>
      <!-- FILE -->
      <b-form-group label="Pièce jointe" label-for="file">
        <b-form-file
          id="file"
          v-model="formdata.fields.file"
          accept="image/*"
          placeholder="Sélectionner un fichier"
          @change="this.$coddyger.updateCurrFile"
        />
        <a v-if="formdata.file" class="text text-danger" @click="emptyFileInput"
          ><small>Retirer fichier</small></a
        >
      </b-form-group>
      <!-- CONTENT -->
      <b-form-group label="Description" label-for="content">
        <b-form-textarea
          id="content"
          v-model="formdata.fields.content"
          rows="4"
          placeholder="Description"
        />
      </b-form-group>

      <!-- submit and reset -->
      <b-row>
        <b-col lg="12"><hr /></b-col>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="ml-1"
            style="float: right"
            :disabled="disableButtonOnLoading"
          >
            <span v-if="!isLoading">Enregistrer</span>
            <span v-if="isLoading">
              <b-spinner small type="grow" />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BFormInput,
  BFormSelect,
  BTable,
  BForm,
  BCardText,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Cleave from "vue-cleave-component";
import payloads from "../payloads.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import PostStore from "@/store/app/post";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormInput,
    BFormSelect,
    BTable,
    BForm,
    BCardText,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormRadioGroup,

    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    api: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      formdata: {
        fields: {
          slug: null,
          title: "",
          content: "",
          category: "",
          file: null,
        },
        payloads: {
          
        },
        options: {
          number: {
            numeral: true,
          },
        },
      },
    };
  },
  computed: {
    buildDataCategory() {
      const { category } = this.$store.state.PostStore;

      return category === undefined ? [] : category;
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true;
      }
      return false;
    },
  },

  methods: {
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },

    emptyFileInput() {
      this.formdata.file = null
    },

    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      let fields = this.formdata.fields;

      if (this.$coddyger.string.is_empty(fields.title)) {
        this.swalError(
          "Erreur",
          this.$coddyger.buildRequiredMessage("Libéllé"),
          "error"
        );
      } else if (this.$coddyger.object.is_empty(fields.category)) {
        this.swalError(
          "Erreur",
          this.$coddyger.buildRequiredMessage("Categorie"),
          "error"
        );
      } else {
        this.isLoading = false;
        const a = Object.assign({}, fields); // Create a copy of the object

        this.sendToStore(a);
      }
    },
    sendToStore(fields) {
      let globalData = {};
      let formData = new FormData();
      formData.append("slug", fields.slug);
      formData.append("file", fields.file);
      formData.append("title", fields.title);
      formData.append("content", fields.content);
      formData.append("category", fields.category.slug);

      globalData = {
        formdata: formData,
        path:
        fields.slug !== null && fields.slug !== ""
            ? this.api.paths.edit
            : this.api.paths.save,
        type: "form-data",
      };

      this.isLoading = true;

      if (fields.slug !== null && fields.slug !== "") {
        // EDIT
        this.$store
          .dispatch("PostStore/edit", globalData)
          .then((a) => {
            this.isLoading = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Info",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Enregistrement modifié avec succès !",
              },
            });
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);

            this.swalError("Erreur", error.detail, "error");
          });
      } else {
        // SAVE
        this.$store
          .dispatch("PostStore/save", globalData)
          .then((a) => {
            this.isLoading = false;
            this.swalError(
              "Succès",
              "Enregistrement éffectué avec succès !",
              "success"
            );

            // Reset fields value
            this.$coddyger.resetObjectValues(this.formdata.fields);
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);

            this.swalError("Erreur", error.detail, "error");
          });
      }
    },
  },
  created() {
    let data = this.data;

    if (this.$coddyger.object.is_empty(data) == false) {
      // Initialise l'identifiant unique par defaut avec la valeur de l'élément sélectionné
      this.formdata.fields.slug = data.slug;

      // Initialise tous les champs avec la valeur de l'élément sélectionné
      this.formdata.fields.title = data.title;
      this.formdata.fields.content = data.content;
      this.formdata.fields.category = data.category;
    }
  },
};
</script>
