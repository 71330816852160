<template>
  <div>
    <b-modal
        id="modal-save"
        ref="modalSave"
        :title="this.$coddyger.object.is_empty(this.details) ? 'Nouveau' : 'Modification - ' + this.details.slug"
        ok-only
        ok-title="Accept"
        size="md"
        hide-footer
        
        centered
        no-close-on-backdrop

        @close="closeModal('modalSave')">
      <save :data="details" :api="api"></save>
    </b-modal>
    <b-modal
        id="modal-details"
        ref="modalDetails"
        :title="details.slug + ' - Détails'"
        ok-only
        size="lg"
        hide-footer
        centered
      >
      <single-view :data="details"></single-view>
    </b-modal>

    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <b-button v-b-modal.modal-save class="mr-1" variant="primary">
              Nouveau
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="tabledata.filter" class="d-inline-block mr-1" placeholder="Recherche..." />
            </div>
          </b-col>
        </b-row>
      </div>
      
      <datatable 
        :data="buildData" 
        :tabledata="tabledata" 
        :showDetails="showDetails" 
        :deletePrompt="deletePrompt" 
        @show-details="showDetails"
        @edit-details="editDetails"
         />
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BModal,
  BButton,
  VBModal,
  BDropdown,
  BDropdownItem,
  BFormInput,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import PostStore from '@/store/app/sample'
import SingleView from './components/single-view.vue'
import Datatable from '@/views/common/datatable.vue'
import Save from './components/save.vue'
import payloads from './payloads'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BModal,
    BButton,
    VBModal,
    BDropdown,
    BDropdownItem,
    BFormInput,

    vSelect,
    SingleView,
    Datatable,
    Save,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      api: {},
      isLoading: false,
      // TABLE PARAMS
      tabledata: {
        isBusy: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 15, 30],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        columns: [
          {
            key: 'index',
            label: '#',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'title',
            label: 'Titre',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'category',
            label: 'Titre',
            sortable: true,
            formatter: val => `${val.title}`,
          },
          {
            key: "created_at",
            label: "Enregistré le",
            sortable: true,
            formatter: (val) => `${val !== null ? `${this.$coddyger.cdgDateFormat(val)}` : ""}`,
          },
          { key: 'actions' },
        ],
        searchTerm: '',
      },
      details: {},
      states: payloads.states
    }
  },
  computed: {
    buildData() {
      const { data } = this.$store.state.PostStore
      this.tabledata.totalRows = data === undefined ? 0 : data.length

      return data === undefined ? [] : data
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true
      }
      return false
    },
    buildUserId() {
      let user = localStorage.getItem('userData')

      return user.id
    }
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore()
    // Build Api
    this.buildApiPayload()
    // Build store data
    this.buildStoreData()

    this.buildForeigners()
  },

  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!PostStore.isRegistered) {
        this.$store.registerModule('PostStore', PostStore)
        PostStore.isRegistered = true
      }
    },
    /* ----------------------------------------
    | APP API
      ---------------------------------------- */
    buildApiPayload() {
      let { path } = this.$router.history.current
      const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
      path = getLastItem(path)

      this.api = {
        route: `apps-${path}`,
        paths: {
          select: `/api/${path}/select`,
          save: `/api/${path}/save`,
          edit: `/api/${path}/edit`,
          remove: `/api/${path}/remove`,
          importer: `/api/${path}/import`,
          selectCategory: `/api/category/select-by-parent/ref-gestion-news`,
        }
      }
    },
    buildStoreData() {
      this.tabledata.isBusy = true;
      this.$store.dispatch('PostStore/fetch', this.api.paths.select).then((a) => {
        this.tabledata.isBusy = false;
      }).catch(() => {
        this.tabledata.isBusy = false;
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erreur',
            icon: 'WarningIcon',
            variant: 'danger',
            text: 'Impossible de charger la liste des données. Merci de réessayer plutard.',
          },
        })
      })
    },
    buildForeigners() {
      this.$store.dispatch('PostStore/fetchCategory', this.api.paths.selectCategory).then().catch((error) => {
        console.log('error', error)
      })
    },

    swal(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    closeModal(modal) {
      console.log('Closing ...')
      this.details = {}

      if(modal === 'modalSave') {
        this.$refs.modalSave.hide()
      } else if(modal === 'modalDetails') {
        this.$refs.modalDetails.hide()
      }
    },

    showDetails(data) {
      this.details = data;
      this.$refs.modalDetails.show()
    },

    editDetails(data) {
      this.details = data;
      this.$refs.modalSave.show()
    },

    deletePrompt(id) {
      this.$router.push({
        query: { ...this.$route.query, id: id },
      })

      this.$bvModal.msgBoxConfirm('Voulez-vous supprimer cet enregistrement ?', {
        title: 'Confirmation',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Oui',
        cancelTitle: 'Non',
        cancelVariant: 'outline-danger',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            this.delete()
          } else {
            this.$router.back()
          }
        })
    },
    delete() {
      const id = this.$route.query.id
      if (id !== '' || id !== null) {
        this.$store
          .dispatch('PostStore/remove', {
            item: id,
            path: this.api.paths.remove,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Action Réussie',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Enregistrement supprimé avec succès!',
              },
            })
          })
          .catch(error => {
            console.log(error)
            this.swal('Erreur', error.message, 'error')
          })
      }
      this.$router.back()
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app

    return {
      appName,
      appLogoImage,
      appDesc,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
